//
// Ribbon
//

.ribbon {
   position: relative;

   .ribbon-target {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      position: absolute;
      z-index: 1;
      background-color: $primary;
      box-shadow: 0px -1px 5px 0px rgba(#000, 0.1);
      color: theme-inverse-color("primary");

      > .ribbon-inner {
         z-index: -1;
         position: absolute;
         padding: 0;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
      }

      &:after {
         border-color: darken($primary, 30%);
      }
   }

   // Vertical aligment
   &-ver {
      .ribbon-target {
         padding: 5px 10px;
         min-width: 36px;
         min-height: 46px;
         text-align: center;
      }
   }

   &.ribbon-top {
      .ribbon-target {
         border-bottom-right-radius: $border-radius;
         border-bottom-left-radius: $border-radius;
      }
   }

   &.ribbon-bottom {
      .ribbon-target {
         border-top-right-radius: $border-radius;
         border-top-left-radius: $border-radius;
      }
   }

   &.ribbon-left {
      .ribbon-target {
         border-top-right-radius: $border-radius;
         border-bottom-right-radius: $border-radius;
      }
   }

   &.ribbon-right {
      .ribbon-target {
         border-top-left-radius: $border-radius;
         border-bottom-left-radius: $border-radius;
      }
   }

   // Clip style
   &.ribbon-clip {
      &.ribbon-left {
         .ribbon-target {
            left: -10px;

            .ribbon-inner {
               border-top-right-radius: $border-radius;
               border-bottom-right-radius: $border-radius;

               &:before,
               &:after {
                  content: "";
                  position: absolute;
                  border-style: solid;
                  border-color: transparent !important;
                  bottom: -10px;
               }

               &:before {
                  border-width: 0 10px 10px 0;
                  border-right-color: $dark !important;
                  left: 0;
               }
            }
         }
      }

      &.ribbon-right {
         .ribbon-target {
            right: -10px;

            .ribbon-inner {
               border-top-left-radius: $border-radius;
               border-bottom-left-radius: $border-radius;

               &:before,
               &:after {
                  content: "";
                  position: absolute;
                  border-style: solid;
                  border-color: transparent !important;
                  bottom: -10px;
               }

               &:before {
                  border-width: 0 0 10px 10px;
                  border-left-color: $dark !important;
                  right: 0;
               }
            }
         }
      }
   }
}
