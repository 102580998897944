//
// Input placeholder color
//

@mixin placeholder($color) {
   &::-moz-placeholder {
      color: $color;
      opacity: 1;
   }
   // See https://github.com/twbs/bootstrap/pull/11526
   &:-ms-input-placeholder {
      color: $color;
   }
   // Internet Explorer 10+
   &::-webkit-input-placeholder {
      color: $color;
   }
   // Safari and Chrome
}
