//
// Timeline 6
//

.timeline.timeline-6 {
   position: relative;

   &:before {
      content: "";
      position: absolute;
      left: 51px;
      width: get($timeline-6-config, bar-border-width);
      top: 0;
      bottom: 0;
      background-color: $gray-200;
   }

   .timeline-item {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 1.7rem;

      &:last-child {
         margin-bottom: 0;
      }

      .timeline-label {
         width: get($timeline-6-config, label-width);
         flex-shrink: 0;
         font-size: 1rem;
         font-weight: 500;
         position: relative;
         color: $dark-75;
      }

      .timeline-badge {
         flex-shrink: 0;
         background: white;
         width: get($timeline-6-config, badge-size);
         height: get($timeline-6-config, badge-size);
         border-radius: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         z-index: 1;
         position: relative;
         margin-top: 1px;
         margin-left: -0.5rem;
         padding: 3px !important;
         border: 6px solid get($timeline-6-config, border-color) !important;

         span {
            display: block;
            border-radius: 100%;
            width: 6px;
            height: 6px;
            background-color: $gray-200;
         }
      }

      .timeline-content {
         flex-grow: 1;
      }
   }
}
