//
// Switch
//

@mixin switch-size($margin, $width, $height, $line-height, $space, $checked-tick-pos, $icon-font-size) {
   input:empty ~ span {
      //line-height: $line-height;
      margin: $margin;
      height: $height;
      width: $width + $space;
      border-radius: ($height/2);
   }

   input:empty ~ span:before,
   input:empty ~ span:after {
      width: $width;
      border-radius: ($height/2);
   }

   input:empty ~ span:after {
      height: $height - 2 * $space;
      width: $height - 2 * $space;
      //line-height: $line-height;
      top: $space;
      bottom: $space;
      margin-left: $space;
      font-size: $icon-font-size;
      text-align: center;
      vertical-align: middle;
   }

   input:checked ~ span:after {
      margin-left: $checked-tick-pos;
   }
}

@mixin switch-default-theme($base, $tick, $checked-base, $checked-tick-bg, $checked-tick-color) {
   // Base
   input:empty ~ span:before {
      background-color: $base;
   }

   // Tick
   input:empty ~ span:after {
      background-color: $tick;
      opacity: 0.7;
   }

   input:checked {
      // Base
      ~ span:before {
         background-color: $checked-base;
      }

      // Tick
      ~ span:after {
         opacity: 1;
         color: $checked-tick-color;
         background-color: $checked-tick-bg;
      }
   }
}

@mixin switch-outline-theme($outline, $tick-bg, $checked-outline, $checked-tick-bg, $checked-tick-color) {
   // Base
   input:empty ~ span:before {
      border: 2px solid $outline;
      background-color: transparent;
   }

   // Tick
   input:empty ~ span:after {
      background-color: $tick-bg;
   }

   input:checked {
      // Base
      ~ span:before {
         border: 2px solid $checked-outline;
         background-color: transparent;
      }

      // Tick
      ~ span:after {
         color: $checked-tick-color;
         background-color: $checked-tick-bg;
      }
   }
}
