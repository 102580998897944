//
// Custom flex helpers
//

@each $breakpoint in map-keys($grid-breakpoints) {
   @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .flex#{$infix}-root {
         flex: 1;
         -ms-flex: 1 0 0px; // IE10-11 fix
      }

      .flex#{$infix}-column-auto {
         flex: none;
      }

      .flex#{$infix}-column-fluid {
         flex: 1 0 auto;
      }

      .flex#{$infix}-row-auto {
         flex: 0 0 auto;
      }

      .flex#{$infix}-row-fluid {
         flex: 1 auto;
         -ms-flex: 1 0 0px; // IE10-11 fix
         min-width: 0;

         @include for-ie11 {
            min-width: none; // IE10-11 fix
         }
      }

      // Center
      .flex#{$infix}-center {
         justify-content: center;
         align-items: center;
      }
   }
}
