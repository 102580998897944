//
// Grid
//

.row {
   // Paddingless row
   &.row-paddingless {
      margin-left: 0;
      margin-right: 0;

      > [class*="col"] {
         padding-left: 0;
         padding-right: 0;
      }
   }

   // Stretch row
   &.row-stretch {
      height: 100%;
   }

   // Stretch column
   .col-stretch {
      display: flex;
      align-items: stretch;
   }

   // Row divider
   &[class*="row-col-divider-"] {
      > div {
         border-bottom: 1px solid $grid-divider-bg;

         &:last-child {
            border-bottom: 0;
         }
      }
   }

   // Responsive breakpoints
   @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
         $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

         &.row-col-divider#{$infix} {
            > div {
               border-bottom: 0;
               border-right: 1px solid $grid-divider-bg;

               &:last-child {
                  border-right: 0;
               }
            }
         }
      }
   }
}

// Gutter helpers
.gutter-b {
   margin-bottom: $grid-gutter-width;
}

.gutter-t {
   margin-top: $grid-gutter-width;
}
