//
// Custom background helpers
//

// Background theme colors
@each $color, $value in $theme-colors {
   // Hover color
   .bg-hover-#{$color} {
      transition: $transition;
      cursor: pointer;

      &:hover {
         transition: $transition;
         background-color: $value !important;
      }
   }

   // Parent hover
   .hoverable {
      &:hover {
         .bg-hover-#{$color} {
            transition: $transition;
            background-color: $value !important;
         }
      }
   }

   // Hover state color
   .bg-hover-state-#{$color} {
      transition: $transition;
      cursor: pointer;

      &:hover {
         transition: $transition;
         background-color: theme-hover-color($color) !important;
      }
   }

   // Diagonal style
   .bg-diagonal-#{$color} {
      &:before {
         background-color: $value !important;
      }
   }

   .bg-diagonal-r-#{$color} {
      &:after {
         background-color: $value !important;
      }
   }

   // Radial gradient colors
   .bg-radial-gradient-#{$color} {
      background-color: $value;
      background-image: radial-gradient(circle, rgba(theme-light-color($color), 0.4), $value 70%);
   }

   // Opacity
   @for $opacity from 1 through 20 {
      .bg-#{$color}-o-#{$opacity * 5} {
         background-color: rgba($value, ($opacity * 20 / 1000)) !important;
      }

      .bg-hover-#{$color}-o-#{$opacity} {
         transition: $transition;
         cursor: pointer;

         &:hover {
            transition: $transition;
            background-color: rgba($value, $opacity * 20 / 100) !important;
         }
      }
   }
}

// Background theme light colors
@each $color, $value in $theme-light-colors {
   // Initial
   .bg-light-#{$color} {
      background-color: $value !important;
   }

   // Hover
   .bg-hover-light-#{$color} {
      transition: $transition;
      cursor: pointer;

      &:hover {
         transition: $transition;
         background-color: $value !important;
      }
   }

   // Diagonal style
   .bg-diagonal-light-#{$color} {
      &:before {
         background-color: $value !important;
      }
   }
}

// Gray background colors
@each $color, $value in $grays {
   .bg-gray-#{$color} {
      background-color: $value !important;
   }

   .bg-hover-gray-#{$color} {
      transition: $transition;
      cursor: pointer;

      &:hover {
         transition: $transition;
         background-color: $value !important;
      }
   }
}

// Background color transparent
.bg-transparent {
   background-color: transparent;
}

.bg-transparent-i {
   background-color: transparent !important;
}

// Background size
.bgi-size-length {
   background-size: length;
}

.bgi-size-cover {
   background-size: cover;
}

.bgi-size-contain {
   background-size: contain;
}

// Background repeat
.bgi-no-repeat {
   background-repeat: no-repeat;
}

// Background positions
.bgi-position-y-top {
   background-position-y: top;
}

.bgi-position-y-bottom {
   background-position-y: bottom;
}

.bgi-position-y-center {
   background-position-y: center;
}

.bgi-position-x-left {
   background-position-x: left;
}

.bgi-position-x-right {
   background-position-x: right;
}

.bgi-position-x-center {
   background-position-x: center;
}

.bgi-position-top {
   background-position: 0 top;
}

.bgi-position-bottom {
   background-position: 0 bottom;
}

.bgi-position-center {
   background-position: center;
}

// Transparent Background
.bg-transparent {
   background-color: transparent;
}

// Diagonal Background
.bg-diagonal {
   position: relative;
   background-color: $white;
   overflow: hidden;

   .card-body {
      position: relative;
      z-index: 2;
   }

   &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-100;
   }

   &:after {
      content: " ";
      width: 200%;
      height: 1000px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 45%;
      transform: rotate(20deg);
      background-color: $white;
   }
}
