//
// Icon
//

// Default icon
i {
   font-size: get($icon-sizes, md);
   color: $text-muted;
}

// Responsive icon sizes
@each $breakpoint in map-keys($grid-breakpoints) {
   @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $name, $value in $icon-sizes {
         .icon#{$infix}-#{$name} {
            font-size: $value !important;
         }
      }
   }
}
