//
// Quick Search
//

.quick-search {
   // Form
   .quick-search-form {
      padding: 0;

      .input-group {
         background-color: transparent;
      }

      .input-group-prepend,
      .input-group-append {
         background-color: transparent;

         .input-group-text {
            background-color: transparent;
            border: 0;
         }
      }

      .input-group-append {
         padding: 0;
         justify-content: center;
         align-items: center;
      }

      .form-control {
         @include input-reset();
         background-color: transparent;
         padding-left: 0;
         padding-right: 0;
         border-top: 0;
         border-left: 0;
         border-right: 0;
         padding: 0;
         border-radius: 0;
      }

      .quick-search-close {
         cursor: pointer;
         display: none;
      }
   }

   // Wrapper
   .quick-search-wrapper {
      display: none;

      // Scroll
      &.ps {
         padding-right: 10px !important;
         margin-right: -10px !important;
      }
   }

   // Result
   .quick-search-result {
      padding: 0;
      margin: 0;
   }

   // Show results
   &.quick-search-has-result {
      .quick-search-wrapper {
         display: block;
      }
   }

   // Dropdown mode
   &.quick-search-dropdown {
      padding: 1rem 1.5rem;

      // Form
      .quick-search-form {
         .input-group {
            &:before {
               right: 0;
            }

            .input-group-prepend {
               .input-group-text {
                  padding-left: 0;
               }
            }

            .input-group-append {
               .input-group-text {
                  padding-right: 0;
               }
            }
         }
      }

      // Wrapper
      .quick-search-wrapper {
         margin: 1.5rem 0 0.5rem 0;
      }

      // Show results
      &.quick-search-has-result {
         .quick-search-form {
            border-bottom: 1px solid $gray-200;
            padding-bottom: 0.75rem;
         }
      }
   }

   // Inline mode
   &.quick-search-inline {
      // Dropdown menu
      .dropdown-menu {
         padding: 1.5rem;
      }
   }

   // Offcanvas mode
   &.quick-search-offcanvas {
      // Form
      .quick-search-form {
         margin-bottom: 1.5rem;

         .form-control {
            font-size: 1rem;
            font-weight: 500;
         }

         .input-group {
            &:before {
               right: 0;
            }

            .input-group-prepend {
               .input-group-text {
                  padding-left: 0;
               }
            }

            .input-group-append {
               .input-group-text {
                  padding-right: 0;
               }
            }
         }
      }

      // Wrapper
      .quick-search-wrapper {
         display: block;
      }
   }
}
