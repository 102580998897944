@media screen and (max-width: 800px) {
   ::ng-deep .desktop-hidden {
      display: initial !important;
   }
   ::ng-deep .mobile-hidden {
      display: none !important;
   }

   .success-dot,
   .void-dot,
   .error-dot,
   .warning-dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
   }
   ::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager li.pages {
      display: none !important;
   }
   .heading-elements {
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
      padding-right: 3rem;
   }
   .heading-elements .dropdown {
      margin-left: 1rem !important;
   }
   .heading-elements .dropdown .btn {
      display: block;
      width: 100%;
   }
   ::ng-deep .ngx-datatable .datatable-body-cell-label :not(a, .icon-circle, .dropdown),
   ::ng-deep .datatable-row-detail :not(a, .icon-circle, .dropdown) {
      font-size: 16px !important;
   }

   ::ng-deep .datatable-row-detail {
      height: 70px !important;
   }
   ::ng-deep .ngx-datatable .datatable-body-cell-label .datatable-icon-right,
   ::ng-deep .ngx-datatable .datatable-body-cell-label .datatable-icon-down {
      font-size: 26px !important;
      margin-left: -10px;
   }
   .icon-circle {
      height: 40px;
      width: 40px;
      border-radius: 10%;
      display: inline-block;
      background-color: #eef0f8 !important;
      /* color fondo #EEF0F8*/
      /* opcion color con fila en hover #d0d2d8*/
   }
   .pt-email-button {
      padding-top: 0.75rem;
   }
   .pt-info-button,
   .pt-null-button,
   .pt-list-button,
   .pt-file-button {
      padding-top: 0.75rem;
   }
   ::ng-deep .ngx-datatable .datatable-body-cell-label .icon-circle > fa-icon > svg {
      font-size: 20px !important;
   }
}

@media screen and (min-width: 801px) {
   .heading-elements {
      display: initial;
   }
   ::ng-deep .desktop-hidden {
      display: none !important;
   }
   ::ng-deep .mobile-hidden {
      display: initial !important;
   }

   ::ng-deep .ngx-datatable .datatable-footer .datatable-pager .pager li.pages {
      display: inline-block !important;
   }

   .icon-circle {
      height: 30px;
      width: 30px;
      border-radius: 10%;
      display: inline-block;
      background-color: #eef0f8 !important;
      /* color fondo #EEF0F8*/
      /* opcion color con fila en hover #d0d2d8*/
   }

   .pt-email-button {
      padding-top: 0.35rem !important;
   }
   .pt-info-button,
   .pt-null-button,
   .pt-list-button,
   .pt-file-button {
      padding-top: 0.35rem !important;
   }
}

.ngx-datatable.material {
   box-shadow: none !important;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
   font-weight: 600 !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
   font-weight: 500 !important;
}
#kt_quick_user_toggle {
   background-color: #eef0f8;
   border: 1px solid #dadada;
}

.text-table-alignment {
   float: left;
}
.phone-table-alignment {
   float: left;
}

.number-table-alignment {
   float: right !important;
   text-align: right !important;
}
.date-table-alignment {
   float: right;
}
.hour-table-alignment {
   float: right;
}
.rut-table-alignment {
   float: right;
}

::ng-deep ng-select.is-invalid .ng-select-container {
   border-color: #f4516c;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

::ng-deep ng-select.is-invalid + .invalid-feedback {
   display: block !important;
}
ng-select.is-invalid + .invalid-feedback {
   display: block !important;
}
.form-control.is-invalid ~ .invalid-feedback,
.ng-select.is-invalid ~ .invalid-feedback {
   display: block !important;
}

.void-dot {
   background-color: white;
   border-radius: 50%;
   display: inline-block;
   border: 2px solid black;
   margin: 2px;
}
.modal-wizard-label {
   display: flex !important;
   flex-direction: column !important;
   justify-content: center !important;
}

.modal-wizard-label .modal-wizard-desc {
   color: #b5b5c3;
}
.svg-flipped {
   transform: scale(-1, 1);
}

.icon-circle > .ng-fa-icon {
   position: relative;
   /* top: calc(50%-1px); */
}

.icon-circle > .ng-fa-icon {
   transition: 0.3s;
}

:host ::ng-deep .change-company-only {
   min-width: 280px !important;
}
:host ::ng-deep .change-company-subsidiary {
   min-width: 560px !important;
}
.change-company .form-group .invalid-feedback {
   display: block !important;
}
:host ::ng-deep .unbreakable-words {
   word-wrap: nowrap !important;
   overflow: visible !important;
}

:host ::ng-deep .divisor-footer {
   border-left: 1px solid rgba(0, 0, 0, 0.12);
   height: 30px;
}
:host ::ng-deep .divisor-footer .page-count {
   height: 30px !important;
   line-height: 30px !important;
}
table.detail-table {
   border: none;
   width: 100%;
}
.detail-table tr td:first-child {
   text-align: left;
}
.detail-table tr td:nth-child(2) {
   text-align: right;
}
::ng-deep .dropdownBotoneraLinea {
   display: inline-block !important;
}
::ng-deep .modal-footer button > span.d-none {
   display: inline-block !important;
}
:host ::ng-deep .ngx-datatable .datatable-body .datatable-scroll {
   display: inherit;
}

:host ::ng-deep .ngx-datatable .datatable-body .datatable-scroll .datatable-row-detail span {
   overflow-wrap: anywhere;
   word-wrap: break-word;
   display: block;
   width: 516px;
}

a {
   cursor: pointer;
   cursor: hand;
}

.current-logo-container img,
.new-logo-container img {
   max-height: 150px !important;
}

.regla-separadora-top {
   border-top: 1px solid #ebedf3;
   padding-top: 1rem !important;
}

:host ::ng-deep app-opciones-referencias-dte #customSwitch1 + label.custom-control-label.label-control {
   font-weight: bold;
}

:host ::ng-deep .alert-icon {
   margin-left: -10px;
   margin-bottom: -10px;
}

/* PASAR A CSS */
/* background colors for icons*/
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle.bg-success:hover {
   background-color: #33b53f !important;
}
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle.bg-danger:hover {
   background-color: #da292e !important;
}
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle.bg-warning:hover {
   background-color: #ff9700 !important;
}
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle.bg-info:hover {
   background-color: #a071ff !important;
}
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle.bg-primary:hover {
   background-color: #4782e1 !important;
}
/*variant for datatables inside modals*/
.modal-dialog .icon-circle.bg-success:hover {
   background-color: #33b53f !important;
}
.modal-dialog .icon-circle.bg-info:hover {
   background-color: #a071ff !important;
}
.modal-dialog .icon-circle.bg-danger:hover {
   background-color: #da292e !important;
}
.modal-dialog .icon-circle.bg-warning:hover {
   background-color: #ff9700 !important;
}
.modal-dialog .icon-circle.bg-primary:hover {
   background-color: #4782e1 !important;
}
td .icon-circle.bg-info:hover {
   background-color: #a071ff !important;
}
td .icon-circle.bg-danger:hover {
   background-color: #da292e !important;
}
td .icon-circle.bg-warning:hover {
   background-color: #ff9700 !important;
}
td .icon-circle.bg-primary:hover {
   background-color: #4782e1 !important;
}
/* colors for icon*/
.icon-circle.bg-success > .ng-fa-icon {
   color: #33b53f !important;
}
.icon-circle.bg-danger > .ng-fa-icon {
   color: #da292e !important;
}
.icon-circle.bg-warning > .ng-fa-icon {
   color: #ff9700 !important;
}
.icon-circle.bg-info > .ng-fa-icon {
   color: #a071ff !important;
}
.icon-circle.bg-primary > .ng-fa-icon {
   color: #4782e1 !important;
}
.icon-circle:hover .ng-fa-icon {
   color: #fff !important;
}
:host ::ng-deep datatable-body-row.datatable-body-row:hover .icon-circle {
   background-color: #d0d2d8 !important;
}
.icon-circle:hover {
   background-color: transparent !important;
}
/*icons for ambient*/
.icon-circle.bg-warning:hover {
   background-color: #ff9700 !important;
}
.icon-circle.bg-danger:hover {
   background-color: #da292e !important;
}
.icon-circle.bg-success:hover {
   background-color: #33b53f !important;
}
.icon-circle.bg-info:hover {
   background-color: #a071ff !important;
}
.icon-circle.bg-primary:hover {
   background-color: #4782e1 !important;
}

/* borde de tablas por linea */
:host ::ng-deep ngx-datatable.material .datatable-body datatable-selection .datatable-row-wrapper {
   border-bottom: 0.4px solid #e4e6ef !important;
}

/* centrado vertical de elementos en tabla */
:host ::ng-deep ngx-datatable.material .datatable-body datatable-selection .datatable-row-wrapper .datatable-body-row > .datatable-row-center > .datatable-body-cell:not(:last-child) {
   display: flex !important;
   align-items: center !important;
}

/* centrado de botonera por linea de tabla */
:host ::ng-deep ngx-datatable.material .datatable-body datatable-selection .datatable-row-wrapper .datatable-body-row > .datatable-row-center > .datatable-body-cell:last-child {
   padding-top: 1.5rem;
}

/* colores de botones */
.btn.btn-primary {
   background-color: #4782e1 !important;
   border-color: #4782e1 !important;
}
.btn.btn-warning {
   background-color: #ff9700 !important;
   border-color: #ff9700 !important;
}
.btn.btn-info {
   background-color: #a071ff !important;
   border-color: #a071ff !important;
}
.btn.btn-success {
   background-color: #33b53f !important;
   border-color: #33b53f !important;
}
.btn.btn-danger {
   background-color: #da292e !important;
   border-color: #da292e !important;
}

/* colores de iconos general */
.svg-icon-success {
   color: #33b53f !important;
}
/* .svg-icon-primary{
    background-color: #4782e1 !important;
  }
  .svg-icon-danger{
    background-color:pink !important;
  } */

/* colores de iconos svg */
:host ::ng-deep .svg-icon.svg-icon-success svg g [fill] {
   transition: fill 0.3s ease;
   fill: #33b53f !important;
}
:host ::ng-deep .svg-icon.svg-icon-danger svg g [fill] {
   transition: fill 0.3s ease;
   fill: #da292e !important;
}
:host ::ng-deep .svg-icon.svg-icon-primary svg g [fill] {
   transition: fill 0.3s ease;
   fill: #4782e1 !important;
}
:host ::ng-deep .svg-icon.svg-icon-warning svg g [fill] {
   transition: fill 0.3s ease;
   fill: #ff9700 !important;
}
:host ::ng-deep .svg-icon.svg-icon-info svg g [fill] {
   transition: fill 0.3s ease;
   fill: #a071ff !important;
}

/* colores de badges */
:host ::ng-deep .bg-primary:not(.icon-circle) {
   background-color: #4782e1 !important;
}
:host ::ng-deep .bg-danger:not(.icon-circle) {
   background-color: #da292e !important;
}
:host ::ng-deep .bg-success:not(.icon-circle) {
   background-color: #33b53f !important;
}
:host ::ng-deep .badge-danger {
   background-color: #da292e !important;
}
:host ::ng-deep .badge-primary {
   background-color: #4782e1 !important;
}

body#kt_body {
   background: #eef0f8 !important;
}

/* colores para menu */
.svg-icon.svg-icon-warning svg g [fill] {
   transition: fill 0.3s ease;
   fill: #ff9700 !important;
}
.svg-icon.svg-icon-success svg g [fill] {
   transition: fill 0.3s ease;
   fill: #33b53f !important;
}
.svg-icon.svg-icon-info svg g [fill] {
   transition: fill 0.3s ease;
   fill: #a071ff !important;
}
.svg-icon.svg-icon-primary svg g [fill] {
   transition: fill 0.3s ease;
   fill: #4782e1 !important;
}
.svg-icon.svg-icon-danger svg g [fill] {
   transition: fill 0.3s ease;
   fill: #da292e !important;
}

:host ::ng-deep .nav.nav-pills .show > .nav-link,
.nav.nav-pills .nav-link.active {
   background-color: #4782e1;
}

:host ::ng-deep .nav .show > .nav-link:not(.active),
.nav .nav-link:hover:not(.active) {
   transition: color 0.15s ease, background-color 0.15s ease, border-color 0s ease, box-shadow 0.15s ease;
   color: #4782e1;
   border: 1px solid #e4e6ef !important;
}

/* titulo en modales */

.modal-wizard-label .modal-wizard-title {
   color: #4782e1;
   font-weight: 600;
   font-size: 1.24rem;
}

/* color dropzone */
ngx-dropzone-label [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
   font-size: 75px;
   color: #4782e1;
}

ngx-dropzone {
   border: 4px dashed tan;
   border-radius: 5px;
   color: #4782e1;
}

/* alternativa para semaforo */
.success-dot,
.void-dot,
.error-dot,
.warning-dot {
   height: 15px;
   width: 15px;
   border-radius: 50%;
}
.success-dot {
   background-color: #33b53f;
   border-radius: 20%;
   display: inline-block;
   margin: 4px;
   transform: rotate(45deg);
}
.error-dot {
   background-color: #da292e;
   border-radius: 20%;
   display: inline-block;
   margin: 4px;
   transform: rotate(45deg);
}
.warning-dot {
   background-color: #ff9700;
   border-radius: 20%;
   display: inline-block;
   margin: 4px;
   transform: rotate(45deg);
}
/* centrado vertical de elementos en tabla */

ngx-datatable.material .datatable-body datatable-selection .datatable-row-wrapper .datatable-body-row > .datatable-row-center > datatable-body-cell.datatable-body-cell,
ngx-datatable.material .datatable-header .datatable-header-cell {
   display: flex !important;
   align-items: center !important;
}
.number-table-alignment,
.date-table-alignment,
.hour-table-alignment,
.rut-table-alignment {
   justify-content: end;
}

.text-table-alignment,
.phone-table-alignment {
   justify-content: start;
}

/* colores menu usuario? */
.btn.btn-light-primary {
   color: #4782e1 !important;
}
.btn.btn-light-danger {
   color: #da292e !important;
}
.btn.btn-light-warning {
   color: #ff9700 !important;
}
.btn.btn-light-primary:hover {
   color: white !important;
   background-color: #4782e1 !important;
}
.btn.btn-light-danger:hover {
   color: white !important;
   background-color: #da292e !important;
}
.btn.btn-light-warning:hover {
   color: white !important;
   background-color: #ff9700 !important;
}

.espacio-boton-soporte {
   /*Se usa cuando se necesita dar espacio a la botonera de facturación y no aplastarla*/
   margin-left: -25rem;
}
